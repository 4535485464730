





























































































































































































































































.ts_1 {
  color: #2878FF !important;
}

.inductrialInjuryPrevention {
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0 !important
  }

  .shell_1 {
    height: 300px;
    background-size: 100% 100%;
    background-position: center;
    background-image: url("../../assets/loginIndexNew/inductrialInjuryPrevention_1.png");
  }

  .shell_2 {
    width: 100%;
    background: #fff;
    overflow: hidden;

    .shell {
      width: 1440px;
      margin: auto;
      padding: 40px 0;

      .s_1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 40px;
        font-weight: 600;
        color: #333333;
      }

      .s_2 {
        width: 100%;
      }
    }
  }

  .shell_3 {
    width: 100%;
    background: #F9FAFC;
    overflow: hidden;

    .shell {
      width: 1440px;
      margin: auto;
      padding: 40px 0;

      .s_1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 40px;
        font-weight: 600;
        color: #333333;
      }

      .s_2 {
        white-space: nowrap;
        overflow-x: auto;

        span {
          margin-right: 40px;
          font-size: 16px;
          color: #666666;
          &:hover {
            color: #2878FF;
            cursor: pointer;
          }
        }
      }

      .s_3 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 24px;

        >div {
          width: 19%;
          margin-right: 1%;
          margin-bottom: 16px;
          border-radius: 6px;
          overflow: hidden;
          height: 236px;
          background: #fff;

          .s_3_1 {
            width: 100%;
            height: 153px;
          }

          .s_3_2 {
            padding: 0 14px;
            line-height: 26px;
            margin-top: 16px;
            font-size: 16px;
            color: #333333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
    }
  }

  .shell_4 {
    width: 100%;
    background: #fff;
    overflow: hidden;

    .shell {
      width: 1440px;
      margin: auto;
      padding: 40px 0;

      .s_1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 600;
        color: #333333;
      }

      .s_2 {
        width: 100%;
        margin-top: 40px;
      }
    }
  }

  .shell_5 {
    width: 100%;
    background: #F9FAFC;
    overflow: hidden;

    .shell {
      width: 1440px;
      margin: auto;
      padding: 40px 0;

      .s_1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 40px;
        font-weight: 600;
        color: #333333;
      }

      .s_2 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        >div {
          width: 32%;
          margin-left: 1.33%;
          margin-bottom: 20px;
          border-radius: 6px;
        }

        .s_2_1 {
          background-image: url("../../assets/loginIndexNew/inductrialInjuryPrevention_11.png");
          padding-left: 20px;
          background-position: center;
          background-size: 100% 100%;
          height: 174px;
          box-shadow: 0px 0px 30px 1px rgba(40,120,255,0.06);
          .s_2_1_1 {
            width: 66px;
            height: 26px;
            margin-top: 42px;
            margin-left: 12px;
          }

          .s_2_1_2 {
            margin-top: 10px;
            margin-left: 12px;
            font-size: 18px;
            font-weight: 600;
            color: #333333;
          }

          &:hover {
            transition: all 0.6s;
            cursor: pointer;
            background-image: url("../../assets/loginIndexNew/inductrialInjuryPrevention_3.png");
            .s_2_1_2 {
              color: #fff;
            }
          }
        }

        .s_2_2 {
          background: #fff;

          .s_2_2_1 {
            width: 110px;
            height: 110px;
            display: block;
            margin: auto;
            margin-top: 20px;
          }

          .s_2_2_2 {
            text-align: center;
            font-size: 12px;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .shell_6 {
    width: 100%;
    background: #fff;
    overflow: hidden;

    .shell {
      width: 1440px;
      margin: auto;
      padding: 40px 0;

      .s_1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 40px;
        font-weight: 600;
      }

      .s_2 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .s_2_1 {
          width: 18%;
          margin-left: 2%;
          height: 373px;
          margin-bottom: 20px;
          border-radius: 6px;
          background-size: cover;
          background-position: center;
          background-image: url("../../assets/loginIndexNew/inductrialInjuryPrevention_6.png");
          position: relative;

          .s_2_1_1 {
            width: 30px;
            height: 30px;
            position: absolute;
            top: -10px;
            left: 14px;
          }

          .s_2_1_2 {
            font-size: 20px;  
            margin-top: 10px;
            text-align: center;
            color: #333333;
          }
          .cc {
            margin-top: 120px !important;
          }

          .s_2_1_3 {
            width: 100px;
            display: block;
            margin: auto;
            margin-top: 90px;
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 1500px) {
  .inductrialInjuryPrevention .shell_1 {
    height: 250px;
  }
}
