.ts_1 {
  color: #2878FF !important;
}
.inductrialInjuryPrevention {
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;
}
.inductrialInjuryPrevention::-webkit-scrollbar {
  width: 0 !important;
}
.inductrialInjuryPrevention .shell_1 {
  height: 300px;
  background-size: 100% 100%;
  background-position: center;
  background-image: url("../../assets/loginIndexNew/inductrialInjuryPrevention_1.png");
}
.inductrialInjuryPrevention .shell_2 {
  width: 100%;
  background: #fff;
  overflow: hidden;
}
.inductrialInjuryPrevention .shell_2 .shell {
  width: 1440px;
  margin: auto;
  padding: 40px 0;
}
.inductrialInjuryPrevention .shell_2 .shell .s_1 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 600;
  color: #333333;
}
.inductrialInjuryPrevention .shell_2 .shell .s_2 {
  width: 100%;
}
.inductrialInjuryPrevention .shell_3 {
  width: 100%;
  background: #F9FAFC;
  overflow: hidden;
}
.inductrialInjuryPrevention .shell_3 .shell {
  width: 1440px;
  margin: auto;
  padding: 40px 0;
}
.inductrialInjuryPrevention .shell_3 .shell .s_1 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 600;
  color: #333333;
}
.inductrialInjuryPrevention .shell_3 .shell .s_2 {
  white-space: nowrap;
  overflow-x: auto;
}
.inductrialInjuryPrevention .shell_3 .shell .s_2 span {
  margin-right: 40px;
  font-size: 16px;
  color: #666666;
}
.inductrialInjuryPrevention .shell_3 .shell .s_2 span:hover {
  color: #2878FF;
  cursor: pointer;
}
.inductrialInjuryPrevention .shell_3 .shell .s_3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 24px;
}
.inductrialInjuryPrevention .shell_3 .shell .s_3 > div {
  width: 19%;
  margin-right: 1%;
  margin-bottom: 16px;
  border-radius: 6px;
  overflow: hidden;
  height: 236px;
  background: #fff;
}
.inductrialInjuryPrevention .shell_3 .shell .s_3 > div .s_3_1 {
  width: 100%;
  height: 153px;
}
.inductrialInjuryPrevention .shell_3 .shell .s_3 > div .s_3_2 {
  padding: 0 14px;
  line-height: 26px;
  margin-top: 16px;
  font-size: 16px;
  color: #333333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.inductrialInjuryPrevention .shell_4 {
  width: 100%;
  background: #fff;
  overflow: hidden;
}
.inductrialInjuryPrevention .shell_4 .shell {
  width: 1440px;
  margin: auto;
  padding: 40px 0;
}
.inductrialInjuryPrevention .shell_4 .shell .s_1 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
  color: #333333;
}
.inductrialInjuryPrevention .shell_4 .shell .s_2 {
  width: 100%;
  margin-top: 40px;
}
.inductrialInjuryPrevention .shell_5 {
  width: 100%;
  background: #F9FAFC;
  overflow: hidden;
}
.inductrialInjuryPrevention .shell_5 .shell {
  width: 1440px;
  margin: auto;
  padding: 40px 0;
}
.inductrialInjuryPrevention .shell_5 .shell .s_1 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 600;
  color: #333333;
}
.inductrialInjuryPrevention .shell_5 .shell .s_2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.inductrialInjuryPrevention .shell_5 .shell .s_2 > div {
  width: 32%;
  margin-left: 1.33%;
  margin-bottom: 20px;
  border-radius: 6px;
}
.inductrialInjuryPrevention .shell_5 .shell .s_2 .s_2_1 {
  background-image: url("../../assets/loginIndexNew/inductrialInjuryPrevention_11.png");
  padding-left: 20px;
  background-position: center;
  background-size: 100% 100%;
  height: 174px;
  box-shadow: 0px 0px 30px 1px rgba(40, 120, 255, 0.06);
}
.inductrialInjuryPrevention .shell_5 .shell .s_2 .s_2_1 .s_2_1_1 {
  width: 66px;
  height: 26px;
  margin-top: 42px;
  margin-left: 12px;
}
.inductrialInjuryPrevention .shell_5 .shell .s_2 .s_2_1 .s_2_1_2 {
  margin-top: 10px;
  margin-left: 12px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}
.inductrialInjuryPrevention .shell_5 .shell .s_2 .s_2_1:hover {
  transition: all 0.6s;
  cursor: pointer;
  background-image: url("../../assets/loginIndexNew/inductrialInjuryPrevention_3.png");
}
.inductrialInjuryPrevention .shell_5 .shell .s_2 .s_2_1:hover .s_2_1_2 {
  color: #fff;
}
.inductrialInjuryPrevention .shell_5 .shell .s_2 .s_2_2 {
  background: #fff;
}
.inductrialInjuryPrevention .shell_5 .shell .s_2 .s_2_2 .s_2_2_1 {
  width: 110px;
  height: 110px;
  display: block;
  margin: auto;
  margin-top: 20px;
}
.inductrialInjuryPrevention .shell_5 .shell .s_2 .s_2_2 .s_2_2_2 {
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
}
.inductrialInjuryPrevention .shell_6 {
  width: 100%;
  background: #fff;
  overflow: hidden;
}
.inductrialInjuryPrevention .shell_6 .shell {
  width: 1440px;
  margin: auto;
  padding: 40px 0;
}
.inductrialInjuryPrevention .shell_6 .shell .s_1 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 600;
}
.inductrialInjuryPrevention .shell_6 .shell .s_2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.inductrialInjuryPrevention .shell_6 .shell .s_2 .s_2_1 {
  width: 18%;
  margin-left: 2%;
  height: 373px;
  margin-bottom: 20px;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/loginIndexNew/inductrialInjuryPrevention_6.png");
  position: relative;
}
.inductrialInjuryPrevention .shell_6 .shell .s_2 .s_2_1 .s_2_1_1 {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -10px;
  left: 14px;
}
.inductrialInjuryPrevention .shell_6 .shell .s_2 .s_2_1 .s_2_1_2 {
  font-size: 20px;
  margin-top: 10px;
  text-align: center;
  color: #333333;
}
.inductrialInjuryPrevention .shell_6 .shell .s_2 .s_2_1 .cc {
  margin-top: 120px !important;
}
.inductrialInjuryPrevention .shell_6 .shell .s_2 .s_2_1 .s_2_1_3 {
  width: 100px;
  display: block;
  margin: auto;
  margin-top: 90px;
  display: none;
}
@media (max-width: 1500px) {
  .inductrialInjuryPrevention .shell_1 {
    height: 250px;
  }
}
